import sass from '../scss/main.scss';
import Inputmask from "inputmask";
import Swiper from 'swiper';

var menu = ['Ремонт бытовой техники', 'Ремонт сантехники', 'Ремонт электрики', 'Мастер на час'];
new Swiper('.banner__container', {
    loop: true,
    slidesPerView: 1,
    speed: 500,
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.banner__container .swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (menu[index]) + '</span>';
        },
    }
});

let bannerContainer = document.querySelector('.banner__container');
if (bannerContainer !== null) bannerContainer.classList.add('vis');

new Swiper('.reviews .swiper-container', {
    loop: true,
    slidesPerView: 1,
    pagination: {
        el: '.reviews .swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
});


//Плавная прокрутка
const anchors = document.querySelectorAll('a[href*="#"]');
for (let anchor of anchors) {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        let blockID = anchor.getAttribute('href');
        if (blockID != '#') {
            document.querySelector(blockID).scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
        }
    })
}